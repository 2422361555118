import React from 'react';
import styled from 'styled-components'

import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as zod from 'zod'

import { SidebarNavigation } from '../components/sidebarNavigation'
import { Spacer } from '../components/common/spacer'
import { Block } from '../components/common/block'
import { Carousel } from '../components/carousel'

import { getFeatured, insertSubscriber } from '../api/api'

import emailLogo from '../assets/email-logo.svg'
import instagramLogo from '../assets/instagram-logo.svg'
import contactLogo from '../assets/contact-logo.svg'


const HomeSection = () => {
  return (
    <ScrollableAnchor id={'home'}>
      <div>
        <Title>Ethtronomy</Title>
        <Description width={500} lineHeight={35}>Catering to the best astrophotography moments that will blow your mind out of this universe.</Description>
        <GetStartedButton>Get Started</GetStartedButton>
      </div>
    </ScrollableAnchor>
  )
}

const AboutSection = () => {
  return (
    <ScrollableAnchor id={'about'}>
      <div>
        <Title>Who we are</Title>
        <Description width={1000}>
          <div>ETHtronomy provides NFT photography with astronomy educational purposes!</div>
          <Spacer axis='vertical' size={20} />
          <div>We cater to the best astrophotography moments that will blow your mind out of this universe. </div>
          <Spacer axis='vertical' size={20} />
          <div>Our interstellar art will transcend you to a different constellation, where you will experience beauty thousands of light years away from earth. </div>
          <Spacer axis='vertical' size={20} />
          <div>This is how ETH will go to the moon and beyond.</div>
          </Description>
      </div>
    </ScrollableAnchor>
  )
}

const FeaturedSection = () => {

  const [featured, setFeatured] = React.useState([])

  React.useEffect(() => {
    getFeatured().then((res) => {
      setFeatured(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <ScrollableAnchor id={'featured'}>
      <div>
        <Title>Featured Arts</Title>
        <Carousel images={featured} />
      </div>
    </ScrollableAnchor>
  )
}

const schema = zod.object({
  name: zod.string().nonempty({ message: 'Name is required' }),
  email: zod
    .string()
    .nonempty({ message: 'Email is required' })
    .email({ message: 'Please use a valid email' })
})

const SubscribeSection = () => {

  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [subscribingStatus, setSubscribingStatus] = React.useState([])
  const { register, handleSubmit, formState, errors, reset } = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onSubmit = async () => {
    let res = await insertSubscriber(name, email)
    setSubscribingStatus(res.data)
    reset()
  }
  
  return (
    <ScrollableAnchor id={'subscribe'}>
      <SubscribeContainer>
        
        <div>
          <Title>Subscribe</Title>
          <Description width={487}>
            Don’t miss out on new artwork. Register now and stay updated with all future updates and artworks.
          </Description>
        </div>

        <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
            <StyledInput
              name="name"
              placeholder="Name"
              type="text"
              onChange={handleNameChange}
              ref={register({ required: true })}
            />

            <FormError>{errors?.name && errors.name.message}</FormError>
            
            <StyledInput
              name="email"
              type="text"
              placeholder="Email"
              onChange={handleEmailChange}
              ref={register({ required: true })}
            />

            <FormError>{errors?.email && errors.email.message}</FormError>

            <Block align='flex-end'>
              <StyledButton>
                Subscribe
              </StyledButton>
            </Block>
            {
              subscribingStatus[0]?.['status-code'] === "201" &&
              <span style={{color: '#734AF6'}}>Thank you for subscribing</span>
            }
            {
              subscribingStatus[0]?.['status-code'] === "400" &&
              <span style={{color: '#734AF6'}}>Email already added</span>
            }
            
        </SubscribeForm>
        
      </SubscribeContainer>
    </ScrollableAnchor>
  )
}

const ContactUsSection = () => {
  return (
    <ScrollableAnchor id={'contact'}>
      <div>

        <Block>
          <Title>Contact us</Title>
          <ContactUsContainer>
            <EthtronomyEmail href="mailto:nft@ethtronomy.com" target="_blank">nft@ethtronomy.com</EthtronomyEmail>
            <img src={emailLogo} />
          </ContactUsContainer>
        </Block>

        <MainInfoContainer>
          <Block>
            <GenericTitle>Contact Details</GenericTitle>
            <div>Toronto, canada</div>
            <div>Street, building</div>
            <div>+1 123 456 7890</div>
          </Block>

          <div>
            <GenericTitle>Social Media</GenericTitle>
            <ContactLinks>
              <Block layout='horizontal' gap={30}>
                <img src={instagramLogo} />
                <span>Ethtronomy</span>
              </Block>
              <Block layout='horizontal' gap={30}>
                <img src={contactLogo} /> 
                <span>Ethtronomy</span>
              </Block>
            </ContactLinks>
          </div>
        </MainInfoContainer>

      </div>
    </ScrollableAnchor>
  )
}


export const Home = () => {

  configureAnchors({offset: -60, scrollDuration: 1000})

  return (
    <Root>

      <SidebarNavigation />

      <ContentContainer>
        <HomeSection />
        <Spacer aixs='vertical' size={300} />
        <AboutSection />
        <Spacer aixs='vertical' size={300} />
        <FeaturedSection />
        <Spacer aixs='vertical' size={300} />
        <SubscribeSection />
        <Spacer aixs='vertical' size={300} />
        <ContactUsSection />
      </ContentContainer>

    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 120px;
  @media only screen and (max-width: 728px) {
    display: block;
    padding: 12px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 180px;
  @media only screen and (max-width: 728px) {
    margin-left: 0;
  }
`

const Title = styled.div`
  font-size: 80px;
  @media only screen and (max-width: 728px) {
    font-size: 40px;
  }
`

const Description = styled.div`
  font-size: 25px;
  width: ${props => props.width && props.width + 'px'};
  line-height: ${props => props.lineHeight && props.lineHeight + 'px'};
  @media only screen and (max-width: 728px) {
    font-size: 20px;
    width: auto;
  }
`

const GetStartedButton = styled.div`
  margin-top: 36px;
  width: 120px;
  display: flex;
  justify-content: center;
  padding: 6px;
  border: 1px solid #FFFFFF;
  border-radius: 27px;
  &:hover {
    cursor: pointer;
  }
`

const StyledInput = styled.input`
  font-size: 20px;
  background: transparent;
  /* padding: 20px 0 25px 0; */
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  width: 400px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #fff;
    font-size: 20px;
  }
  @media only screen and (max-width: 728px) {
    width: auto;
  }
`

const StyledButton = styled.button`
  width: 120px;
  color: #270078;
  border-radius: 27px;
  background: #ffffff;
  border: 1px solid #FFFFFF;
  height: 38px;
  text-align: center;
  transition: .5s;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default !important;
  }
`

const ContactUsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const ContactLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 728px) {
      display: flex;
      align-items: center;
      justify-content: initial;
    }
  }
`

const SubscribeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  @media only screen and (max-width: 728px) {
    display: flex;
    align-items: initial;
    flex-direction: column;
    width: auto;
  }
`

const MainInfoContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 60px;
  @media only screen and (max-width: 728px) {
    display: flex;
    flex-direction: column;
  }
`

const EthtronomyEmail = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  &:hover {
    cursor: pointer;
  }
`

const GenericTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
`

const SubscribeForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
  @media only screen and (max-width: 728px) {
    margin-top: 30px;
  }
`

const FormError = styled.span`
  font-size: 12px;
  color: #f04e4f;
  font-weight: bold;
`;