import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from '../pages/home'
import { Gallery } from '../pages/gallery'
import { Overview } from '../pages/overview'

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/gallery" exact component={Gallery} />
      <Route 
        path="/product-overview/:id"
        render={props => <Overview key={props.match.params.id} {...props} />} 
        exact 
      />
    </Switch>
  );
}

export default Routes;