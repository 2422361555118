import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { getAll } from '../api/api'

export const Gallery = () => {

  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    getAll().then((res) => {
      setItems(res.data)
    })
  }, [])

  return (
    <Root>
      
      <MainContainer>
        <div>
          <Title>Gallery</Title>
          <Description>
            Receive your content with a high-resolution, watermark-free copy upon purchasing the NFT.
          </Description>
        </div>

        <GalleryContainer>
          {items.map((g, index) => (
            <Link key={index} to={`/product-overview/${g.uuid}`}>
              <ItemContainer>
                <NFTImage src={g.image_url} />
                <NFTName>{g.title}</NFTName>
                <NFTType>{g.type}</NFTType>
              </ItemContainer>
            </Link>
          ))}

        </GalleryContainer>
      </MainContainer>
      
    </Root>
  )
}

const Root = styled.div`
  margin: 120px auto;
  width: 900px;
  position: relative;
  z-index: 10;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media only screen and (max-width: 728px) {
    width: 100%;
  }
`

const MainContainer = styled.div`
  padding: 12px;

`

const Title = styled.div`
  font-size: 80px;
  @media only screen and (max-width: 728px) {
    font-size: 40px;
  }
`

const Description = styled.div`
  font-size: 25px;
  @media only screen and (max-width: 728px) {
    font-size: 20px;
  }
`

const ItemContainer = styled.div`
  @media only screen and (max-width: 728px) {
    margin-top: 30px;  
  }
`

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1000;
  gap: 30px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 728px) {
    justify-content: center;
  }
`

const NFTImage = styled.img`
  border-radius: 10px;
  width: 400px;
  height: 250px;
`

const NFTType = styled.div`
  opacity: .47;
`

const NFTName = styled.div`
  font-size: 20px;
`