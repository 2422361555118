import React from 'react'
import styled from 'styled-components'

import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import ethtronomyLogo from '../assets/ethtronomy_logo.png'

export const Header = () => {
  const location = useLocation();
  return (
    <Root locationPathName={location.pathname}>
      <Link to='/'>
        <WebsiteLogo src={ethtronomyLogo} />
      </Link>

      <Navigation>
        <Link to='/'>About</Link>
        <Link to='/gallery'>Gallery</Link>
        <a href="https://mintable.app/u/ethtronomy" target="_blank">Buy Now</a>
      </Navigation>
    </Root>
  )
}

const Root = styled.div`
  position: ${props => props.locationPathName.includes('/product-overview/') ? 'absolute' : 'sticky'};
  top: 0;
  left: 0;
  right: 0;
  width: 90%;
  margin: 10px auto;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 728px) {
    display: flex;
  }
`


const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      width: 100px;
      color: #270078;
      border-radius: 27px;
      background: #ffffff;
      border: 1px solid #FFFFFF;
      height: 28px;
      padding: 6px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 728px) {
    gap: 20px;
    a {
      margin-left: 0px;
      font-size: 15px;
      &:last-child {
        width: 65px;
        height: auto;
        padding: 5px;
        text-align: center;
      }
    }
  }
`

const WebsiteLogo = styled.img`
  height: 64px;
  width: 182px;
  @media only screen and (max-width: 728px) {
    height: 40px;
    width: 120px;
  }
`