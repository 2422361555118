import React from 'react'

import {
  BrowserRouter as Router,
} from "react-router-dom"

import Routes from './routes/routes'

import { Header } from './components/header'
import { Footer } from './components/footer'
import { ScrollToTop } from './components/common/scrollToTop'
import './App.css';

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
