import React from 'react'
import styled from 'styled-components'

import HomeLogo from '../assets/home.svg'
import featuredLogo from '../assets/featured.svg'
import aboutLogo from '../assets/about-logo.png'
import subscribeLogo from '../assets/subscribe.svg'
import contactLogo from '../assets/contact.png'

// active nav will display these icons
import homeActiveLogo from '../assets/home-active.png'
import aboutActiveLogo from '../assets/about-active.png'
import featuredActiveLogo from '../assets/featured-active.png'
import subscribeActiveLogo from '../assets/subscribe-active.png'
import contactActiveLogo from '../assets/contct-active.png'

export const SidebarNavigation = () => {

  const [activeNav, setActiveNav] = React.useState("")

  const doSomethingWithChangeFunction = () => { 
    let urlParam = window.location.hash;
    setActiveNav(urlParam)
  };

  window.addEventListener('hashchange', doSomethingWithChangeFunction);

  const navItems = [
    {name: 'Home', href: '#home', icon: HomeLogo, activeIcon: homeActiveLogo},
    {name: 'About', href: '#about', icon: aboutLogo, activeIcon: aboutActiveLogo},
    {name: 'Featured', href: '#featured', icon: featuredLogo, activeIcon: featuredActiveLogo},
    {name: 'Subscribe', href: '#subscribe', icon: subscribeLogo, activeIcon: subscribeActiveLogo},
    {name: 'Contact', href: '#contact', icon: contactLogo, activeIcon: contactActiveLogo},
  ]

  return (
    <Root>
      {navItems.map((nav, index) => (
        <NavigationLink
          key={index} 
          href={nav.href} 
          active={nav.href === activeNav}
        >
          <div><img src={nav.href === activeNav ? nav.activeIcon : nav.icon} /> </div>
          <span>{nav.name}</span>
        </NavigationLink>
      ))}
    </Root>
  )
}

const Root = styled.div`
  position: fixed;
  top: 35%;
  left: 5%;
  right: 0;
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  @media (max-width: 600px) {
    display: none;
  }
`

const NavigationLink = styled.a`
  display: flex;
  align-items: center;
  gap: 20px;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }

  div {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${props => props.active ? '#ffffff' : '#474858'};
    img {
      width: 10px;
      height: 10px;
      text-decoration: none;
    }
  }

  span {
  }
`