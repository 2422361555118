import axios from './axiosInstace'

export const getAll = () => axios.get('/getall/')

export const getById = (id) => axios.post(`/getbyid/`, {
  uuid: id
})

export const getFeatured = () => 
  axios.get('/getFeatured/')

export const insertSubscriber = (name, email) => 
  axios.post('/insertsubscriber/', {
    name: name,
    email: email
  })
