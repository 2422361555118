import React from 'react'
import styled from 'styled-components'

import instagramLogo from '../assets/instagram-logo.svg'
import contactLogo from '../assets/contact-logo.svg'


export const Footer = () => {
  return (
    <Root>
      <a href="https://www.instagram.com/ethtronomy/" target="_blank"><img src={instagramLogo} /></a>
      <a href="https://mintable.app/u/ethtronomy" target="_blank"><img src={contactLogo} /></a>
    </Root>
  )
}

const Root = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  a {
    margin: 10px;
  }
`