import React from 'react'
import styled from 'styled-components'

import { Link, useParams } from 'react-router-dom'
import { Spacer } from '../components/common/spacer'
import { Block } from '../components/common/block'
import { getById } from '../api/api'
import backLogo from '../assets/back-logo.svg'

export const Overview = () => {

  const { id } = useParams();
  const [product, setProduct] = React.useState([])

  React.useEffect(() => {
    getById(id).then((res) => {
      setProduct(res.data)
    })
  }, [])

  return (
    <div>
      
      <Banner image={product[0]?.image_url}>
        <div style={{width: '70%', margin: 'auto'}}>
        <BannerMainTitle>{product[0]?.title}</BannerMainTitle>
        <NFTType>{product[0]?.type}</NFTType>
        </div>
      </Banner>

      <Spacer axis='vertical' size={20} />

      <BackButtonContainer>
        <Link to="/gallery"><img src={backLogo} /></Link>
        <span>Back</span>
      </BackButtonContainer>

      <Spacer axis='vertical' size={30} />

      <Root>

        <Header>
          <MainTitle>{product[0]?.title}</MainTitle>
          <BuyProductButton href={product[0]?.mintable_url} target='_blank'>Buy on website</BuyProductButton>
        </Header>

        <Spacer axis='vertical' size={30} />

        <Subtitle>
          Receive your content with a high-resolution, watermark-free copy upon purchasing the NFT.
        </Subtitle>

        <Spacer axis='vertical' size={40} />

        <Description>
          {product[0]?.description}
        </Description>

          <Spacer axis='vertical' size={50} />

          <MainTitle>Similiar Projects</MainTitle>

          <Spacer axis='vertical' size={20} />

          <GalleryContainer layout='horizontal' gap={46} wrap>
            {product[0]?.similar_projects.map((g, index) => (
              <Link key={index} to={`/product-overview/${g.Uuid}`}>
                <div>
                  <NFTImage src={g.image_url} />
                  <NFTName>{g.title}</NFTName>
                  <NFTType>{g.type}</NFTType>
                </div>
              </Link>
            ))}

          </GalleryContainer>

      </Root>
    </div>
  )
}

const Banner = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #111; //  fallback 
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const BannerMainTitle = styled.div`
  font-size: 5vw;
`

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  gap: 10px;
  width: 80%;
  &:hover {
    cursor: pointer;
  }

  img {
    background-color: #474858;
    border-radius: 50%;
    padding: 5px;
    width: 22px;
    height: 22px;
  }

  @media only screen and (max-width: 728px) {
    width: 90%;
    img {
      margin-right: 10px;
    }
  }
`

const Root = styled.div`
  margin: auto;
  padding: 10px;
  width: 60%;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media only screen and (max-width: 728px) {
    width: 90%;
    padding: 10px;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const MainTitle = styled.div`
  font-weight: bold;
  font-size: 50px;
  @media only screen and (max-width: 728px) {
    font-size:  19px;
  }
`

const BuyProductButton = styled.a`
  width: 150px;
  border-radius: 27px;
  background: #ffffff;
  color: #270078 !important;
  border: 1px solid #FFFFFF;
  height: 28px;
  font-size: 15px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 728px) {
    padding: 3px;
    width: 100px;
    font-size: 12px;
  }
`

const Subtitle = styled.div`
  font-size: 20px;
`

const Description = styled.div`
  opacity: .8;
  width: 100%;
`

const GalleryContainer = styled(Block)`
  &:hover {
    cursor: pointer;
  }
`

const NFTType = styled.div`
  opacity: .47;
  font-size: 19px;
`

const NFTName = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const NFTImage = styled.img`
  border-radius: 10px;
  width: 400px;
  height: 250px;
`
