import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { motion, AnimatePresence } from 'framer-motion'
import leftArrow from '../assets/left-arrow.png';
import rightArrow from '../assets/right-arrow.png';

export const Carousel  = ({images}) => {

  const [activeImage, setActiveImage] = React.useState(0)

  const handlePreviousImageClick = () => {
    setActiveImage((prevIndex) => {
      const nextIndex = prevIndex - 1
      return nextIndex < 0 ? images.length - 1 : nextIndex
    })
  }

  const handleNextImageClick = () => {
    setActiveImage((prevIndex) => {
      const nextIndex = prevIndex + 1
      return nextIndex > images.length - 1 ? 0 : nextIndex
    })
  }

  return (
    <Container>

      <AnimatePresence>
        <Link to={`/product-overview/${images?.[activeImage]?.uuid}`}>
          <SlideImage 
            key={activeImage}
            src={images?.[activeImage]?.image_url} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.2 }}
          />
        </Link>
      </AnimatePresence>

      <CourselButtonsContainer>
        <LeftArrowContainer onClick={handlePreviousImageClick}>
          <img src={leftArrow} />
        </LeftArrowContainer>

        <RightArrowContainer onClick={handleNextImageClick}>
          <img src={rightArrow} />
        </RightArrowContainer>

      </CourselButtonsContainer>

    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 600px;
  height: 350px;
  margin: auto;
  @media only screen and (max-width: 728px) {
    width: auto;
  }
`

const SlideImage = styled(motion.img)`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  /* max-width: 100%;
  min-width: 100px;
  min-height: 100px; */
  user-select: none;
  border-radius: 8px;
`

const CourselButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  opacity: .4;
  transition: .5s;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`

const LeftArrowContainer = styled.div`
  left: 10px;
  right: unset;
`

const RightArrowContainer = styled.div`
  right: 10px;
  left: unset;
`